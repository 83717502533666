/* eslint-disable no-console */
import React from "react";
import {
  ApolloClient,
  ApolloProvider as OGApolloProvider,
  DefaultOptions,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";
import fetch from "cross-fetch";
import { IncomingMessage, ServerResponse } from "http";
import Cookies from "next-cookies";
import { API_URL } from "src/config";
import useMemoizedChildren from "hooks/useMemoizedChildren";

export type ResolverContext = {
  req?: IncomingMessage;
  res?: ServerResponse;
};

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
let authorization = "";

const uploadLink = createUploadLink({
  uri: `${API_URL}/graphql`,
  fetch: (...args) => fetch(...args),
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }): any => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path, extensions }) => {
        switch (extensions?.code) {
          case "UNAUTHENTICATED":
            console.error(
              `[Authentication error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
            break;

          default:
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            );
        }
      });

    if (networkError) {
      switch ((networkError as ServerError).statusCode) {
        case 401:
          // A 401 on the network indicates that the token
          // is not valid, and will never be valid, so must not
          // used for any further requests
          // TODO clear the auth token (cookie)

          // Copy the failed request, without a token
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: null,
            },
          });

          // Bounce back up the link chain, it will only be retried once
          // this ensures that, even with a bad token the first time,
          // a call to Query.session will eventually succeed transparently
          return forward(operation);
        default:
          throw networkError;
      }
    }
  }
);
const createApolloClient = (context?: ResolverContext) => {
  if (context) {
    const { token } = Cookies(context);
    authorization = token;
  }

  const authLink = setContext(async (_, { headers }) => {
    if (authorization === null) {
      return { headers };
    }

    return {
      headers: {
        ...headers,
        authorization,
      },
    };
  });

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "cache-first",
      errorPolicy: "all",
    },
    mutate: {
      errorPolicy: "all",
    },
  };

  const cache = new InMemoryCache();

  const link = from([authLink, errorLink, uploadLink as any]);

  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link,
    cache,
    defaultOptions,
  });
};

export const initializeApollo = (
  initialState: any = null,
  context?: ResolverContext
) => {
  const _apolloClient = apolloClient ?? createApolloClient(context);

  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

const ApolloProvider = ({ initialState, children }) => {
  const apolloClient = initializeApollo(initialState);
  const memoizedChildren = useMemoizedChildren(children);

  return (
    <OGApolloProvider client={apolloClient}>
      {memoizedChildren}
    </OGApolloProvider>
  );
};

export default ApolloProvider;
